<template>
  <div class="refund">
    <header class="header">
      <van-row type="flex" align="center">
        <img :src="refundMap.get(status).icon" alt />
        <span>{{refundMap.get(status).value}}</span>
      </van-row>
      <div class="reject-reason" v-if="status == 9">驳回原因：{{ reasonRejection }}</div>
    </header>
    <main class="main">
      <van-form @submit="onSubmit" v-if="[1,3,8,9].includes(status)">
        <van-cell-group :border="false">
          <van-field
            v-model="form.realName"
            label="真实姓名："
            label-align="right"
            placeholder="请输入真实姓名"
          />
          <van-field
            v-model="form.account"
            label="支付宝账号："
            label-align="right"
            placeholder="请输入支付宝账号"
          />
          <van-field v-model="form.refundMoney" readonly label="预计可退：" class="refund-price" label-align="right" />
          <van-field
            v-model="form.refundReason"
            class="refund-reason-input"
            label="退款原因："
            type="textarea"
            required
            label-align="right"
            placeholder="请告诉我们退款原因，我们会好好改进的"
            :rules="[{ required: true, message: '请填写退款原因' }]"
          />
        </van-cell-group>
        <div class="danger-text">请务必填写正确的支付宝账号，一旦错误无法挽回！！！</div>
        <van-button size="normal" class="submit" native-type="submit">{{status == 8 ? '提交申请' : '重新提交'}}</van-button>
      </van-form>
      <van-cell-group :border="false" v-if="[2,6,10].includes(status)">
        <van-cell title="真实姓名：" :value="form.realName" />
        <van-cell title="支付宝账号：" :value="form.account" />
        <van-cell title="预计可退：" class="refund-price" :value="`￥${form.refundMoney || 0}`" />
        <van-cell
          title="实际退款："
          v-if="status == 6"
          class="refund-price"
          :value="`￥${form.refundMoney || 0}`"
        />
        <van-cell title="退款原因：" :label="form.refundReason" />
      </van-cell-group>
      <div class="explain" v-if="status == 8">
        <div class="explain-title">重要说明：</div>
        <ul class="explain-content">
          <li>请务必填写真实姓名，仔细填写支付宝账号，如填写错误导致发错款项，平台不承担任何责任</li>
          <li>当前填写的姓名必须是支付宝实名的姓名，否则无法支付</li>
          <li>预计退款金额只是参考，以最终退款金额为主。为避免误差，请退款过程中，请勿继续使用产品</li>
        </ul>
      </div>
    </main>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import { debounce } from "lodash";
export default {
	setup() {
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    const { $tool: tool, $router: router, $store: store, $http: http } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    let status = Number(route.query.status);
    const state = reactive({
      status,
      reasonRejection: "",
      form: {
        realName: "",
        account: "",
        refundMoney: "",
        refundReason: ""
      },
      refundMap: new Map([
        [
					1,
					{ value: '待申请', icon: require('../../assets/image/personal/refund_ready.png') }],
					[8,
          { value: '待申请', icon: require('../../assets/image/personal/refund_ready.png') },
        ],
        [ 2,
          { value: '审核中', icon: require('../../assets/image/personal/refund_ready.png') }],
					[10, 
					{ value: '审核中', icon: require('../../assets/image/personal/refund_ing.png') }
				],
        [
					3,
					{ value: '已驳回', icon: require('../../assets/image/personal/refund_reject.png') }],
          [9,
          { value: '已驳回', icon: require('../../assets/image/personal/refund_reject.png') },
        ],
        [
          6,
          { value: '已退款', icon: require('../../assets/image/personal/refund_success.png') },
        ],
      ])
    });
    const methods = {
      // 获取可退金额
      getRefundableAmount() {
				let statusArray = [2,6,10]
        http
          .post("/card/v2/getRefundableAmount", {
            card: store.state.user.userInfo.iccid,
            orderId: route.query.id
          })
          .then(({ data }) => {
            if (data.code === 0 && statusArray.includes(state.status)) {
              state.form.realName = data.refundAccountRealName; //真实姓名
              state.form.account = data.refundAccount; //支付宝账号
              state.form.refundMoney = data.estRefundAmount; //预计可退金额
							state.form.refundReason = data.refundReason //退款原因
              return;
            }
            if (data.code === 0) {
              state.form.refundMoney = data.estRefundAmount; //预计可退金额
              state.reasonRejection = data.reasonRejection; // 驳回信息
              return;
            }
            tool.toast({
              msg: data.msg
            });
          });
      },
      //按钮提交
      onSubmit: debounce(
        function() {
          http
            .post("/card/v2/markRefund", {
              card: store.state.user.userInfo.iccid,
              orderId: route.query.id,
              ...state.form
            })
            .then(({ data }) => {
              if (data.code === 0) {
                tool.toast(
                  {
                    msg: "退款申请成功",
                    duration: 3000
                  },
                  () => {
                    router.push({
           						name: "personal"
          					});
                  }
                );
                return;
              }
              tool.toast({
                msg: data.msg
              });
            });
        },
        1000,
        { leading: true, trailing: false }
      )
    };
    onMounted(() => {
      ctx.getRefundableAmount();
    });
    return { ...toRefs(state), ...methods };
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/theme/index";
.refund {
  background: #F7F6FD;
  display: flex;
  flex-direction: column;
	height: 100vh;
  .header {
    margin: 24px 30px;

    img {
      width: 60px;
      margin-right: 16px;
    }

    span {
      font-size: 32px;
      color: #000018;
    }

    .reject-reason {
      margin-top: 38px;
      font-weight: 500;
      font-size: 28px;
    }
  }

  .main {
    flex: 1;
    background: #ffffff;
    border-radius: 32px 32px 0 0;
    /deep/ .van-cell-group {
      background: transparent;

      .van-cell {
        background: transparent;
      }

      .van-cell__title {
        color: #000018;
      }

      .van-cell--required::before {
        left: 50px;
      }

      .van-field__control::placeholder {
        color: #999999 !important;
      }

      .refund-reason-input {
        display: block;

        .van-cell__value {
          border: 1px solid #eaedf2;
          border-radius: 16px;
          padding: 24px;
          margin-top: 20px;
        }
      }

      .refund-price {
        .van-cell__value, .van-field__control {
          color: #ff5c00;
        }
      }
    }

    .danger-text {
      color: #f35252;
      font-size: 24px;
      padding: 0 30px;
      margin-top: 20px;
    }

    .submit {
       @include background_color("background_color1");
      border-radius: 36px;
      color: #ffffff;
      padding: 22px 116px;
      margin: 70px auto 0;
      display: block;
    }

    .explain {
      border: 1px dashed #b4b4b4;
      border-radius: 8px;
      padding: 28px 32px;
      margin: 44px 30px;
      font-size: 24px;

      .explain-title {
        color: #000018;
      }

      .explain-content {
        color: #858b95;

        ul {
          margin-top: 8px;
        }

        li {
          list-style: inside;
          margin-top: 12px;
          line-height: 40px;
        }
      }
    }
  }
}
</style>
